.navbar {
    background-color: #5d91c8;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-family: Georgia, "Times New Roman", Times, serif;
}

.navbar a {
    float: left;
    display: block;
    color: hwb(0 1% 94%);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.navbar a:hover {
    background-color: hsl(59, 80%, 65%);
    color: #7f2982;
}

.navbar .icon {
    display: none;
}
