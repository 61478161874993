body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.container {
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    color: #333333;
}

button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

.card-body {
    background-color: #6ae4d4;
}

h1 {
    font-family: fantasy;
}
