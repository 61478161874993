.profiles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url("../public/form.jpg"); /* Adjust the path based on your image location */
    background-size: cover;
    background-repeat: no-repeat;
}

.profiles-form {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profiles-form h2 {
    margin-bottom: 10px;
}

.profiles-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.profiles-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.profiles-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profiles-form p {
    text-align: center;
    margin-top: 10px;
    color: #007bff;
    cursor: pointer;
}
